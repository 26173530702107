import React from 'react';
import TituloKitCompetencia from '../Recursos/Kit de Competencia/Titulo Kit de Competencia-01.png';
import KitCompetencia from '../Recursos/Kit de Competencia/Kit de Competencia-01.png';
import TextoKitCompetencia from '../Recursos/Kit de Competencia/Texto Kit de Competencia-01.png';
import LogoSponsors from '../Recursos/Logo BR sponsors-01.png';

const Kit = () => {
  return (
    <div className='Kit-container'>
      <img src={TituloKitCompetencia} alt="Titulo Kit Competencia" className='titulo-kit-de-competencia'/>
      <div className='custom-bg'>
        <div className='Kit-banner'>
          <img src={KitCompetencia} alt="img kit competencia" className='img-kit-competencia'/>
          <div className='texto-kit-container'>
            {/*<img src={TextoKitCompetencia} alt="Texto Kit Competencia" className='texto-kit-competencia'/>*/}
          </div>
        </div>
      </div>
      <div className='sponsors-container'>
        <img src={LogoSponsors} alt="Logo Sponsors" className='logo-sponsors'/>
      </div>
    </div>
  );
};

export default Kit;
