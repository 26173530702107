import React, { useState } from 'react';
import TituloCircuitos from '../Recursos/Circuito/Titulo Circuitos-01.png';
import TextoCircuito from '../Recursos/Circuito/Texto-01.png';
import BotonCircuitos from '../Recursos/Circuito/Boton Descargar Circuito-01.png';
import Agenda from '../Recursos/Agenda/Agenda-01.png';
import ImagenAgenda from '../Recursos/IBS_2017.02.11.jpg';
import TextoFecha from '../Recursos/Inscripción/texto Fecha-01.png';
import Imagen2k from '../Recursos/Carrusel/Circuito 2K.png';
import Imagen5k from '../Recursos/Carrusel/Circuito 5K.png';
import Imagen10k from '../Recursos/Carrusel/Circuito 10K.png';
import Title2k from '../Recursos/Carrusel/Titulo 2K-01.png';
import Title5k from '../Recursos/Carrusel/Titulo 5K-01-01.png';
import Tile10k from '../Recursos/Carrusel/Titulo 10K-01.png';

const Circuitos = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const images = [Imagen2k, Imagen5k, Imagen10k];

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
    };

    const handleDescargaCircuitoClick = () => {
        if (images[0]) {
            // aqui descargo el de 2k
        } else if (images[1]) {
            // aqui descargo el de 5k
        } else if (images[2]) {
            // aqui descargo el de 10k
        }
    };

    return (
        <div>
            <div className="circuitos-container" id='comollegar'>
                <img src={TituloCircuitos} alt="Circuitos" className="titulo-circuitos"/>
                <div className="imagen-circuitos-container">
                    <button className="prev" onClick={prevSlide}>&#10094;</button>
                    <img src={images[currentSlide]} alt={`Circuito ${currentSlide + 1}`} className="imagen-circuitos"/>
                    <button className="next" onClick={nextSlide}>&#10095;</button>
                    <button className="boton-circuitos" onClick={handleDescargaCircuitoClick}>
                        <img src={BotonCircuitos} alt="Boton Circuitos"/>
                    </button>
                </div>
            </div>
                    <div>
                        <img src={TextoCircuito} alt='Textocircuitos' className='TextoCircuitos'/>
                    </div>
            <div className="agenda-container">
                <img src={Agenda} alt="Agenda Texto" className="agenda-texto"/>
                <img src={TextoFecha} alt="Texto de fecha" className="linea-agenda"/>
                <img src={ImagenAgenda} alt="Agenda Imagen" className="imagen-agenda"/>
            </div>
        </div>
    );
};

export default Circuitos;
