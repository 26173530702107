import React from 'react';

import Header from '../Componentes/Header';
import ImagenFecha from '../Recursos/IBS_2016.08.14.jpg';
import TextoFecha from '../Recursos/Inscripción/texto Fecha-01.png';
import BotonInscribeteAqui from '../Recursos/Inscripción/Boton Inscríbete Aquí-01.png';

const Inicio = ({ onInscribeteClick, onFaqClick }) => {
    return (
        <div>
            
            <div className='fecha-container'>
                <div className="imagen-fecha-container">
                    <img src={ImagenFecha} alt="Imagen de fecha" className="imagen-fecha"/>
                    <img src={TextoFecha} alt="Texto de fecha" className="texto-fecha"/>
                    <h1 className="texto-fecha2 texto-fecha2-correct">DOMINGO 01 DE SEPTIEMBRE</h1>
                    <h1 className="texto-fecha2"> </h1>
                    <h2 className="texto-fecha3" id='info'>CIUDAD EMPRESARIAL
HUECHURABA</h2>
                </div>
                <div className='nuevo-texto-container'>
                    <p className='nuevo-texto' >Porque tú puedes ser runner! </p>

                    <p className='nuevo-texto'>El 2024 será un hito porque Celebraremos el 65vo aniversario de BarbieTM con un evento único que llega a revolucionar las calles de Santiago.</p>

                    <p className='nuevo-texto'>Es una invitación a correr para que logres tu desafío, llegues a la meta y triunfes al estilo de Barbie.</p>

                    <p className='nuevo-texto'>La primera carrera donde están todos invitados a correr de rosado en una mañana que será de mucha energía, fuerza, inclusión y empoderamiento.</p>

                    <p className='nuevo-texto'>Porque todos podemos ser lo que queremos ser y juntos cruzar la meta de este desafío.</p>
            </div>
            </div>
        </div>
    );
};

export default Inicio;
